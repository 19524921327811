import * as firebase from "firebase";

const firebaseConfig = {
	apiKey: "AIzaSyC5vv36vjpc7-4bz5W4EVsyoTyO_mVYYFY",
	authDomain: "all-in-one-crm-b8cdf.firebaseapp.com",
	databaseURL: "https://global-insurance-llc.firebaseio.com",
	projectId: "all-in-one-crm-b8cdf",
	storageBucket: "all-in-one-crm-b8cdf.appspot.com",
	messagingSenderId: "636371279703",
	measurementId: "G-J95BDDN8BF",
	appId: "1:636371279703:web:50e1079a2ffa94e7516812",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const storage = firebase.storage();

export { firestore, storage };
